<template>
	<div class="detail">
		<van-nav-bar
			title="文章詳情"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="main qjc-texta-l">
			<p class="title qjc-ftw-b qjc-c-dark">{{ detail.title }}</p>
			<p class="title-sub qjc-fts-24">
				<span>{{ detail.source }}</span>
				<span>{{ detail.created_at }}</span>
			</p>
			<div class="content qjc-c-dark" v-html="detail.content">
				
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(Toast);
	
	import { studyDetail } from "@/js/axios"
	
	export default{
		name: 'studyDetail',
		data (){
			return {
				detail: {
					title: '',
					source: '',
					read: 0,
					content: '',
					created_at: ''
				}
			}
		},
		mounted (){
			studyDetail(this.$route.params.id).then(res => {
				// console.log(res);
				if(res.status == 1){
					this.detail = res.data;
				}else{
					Toast.fail(res.msg);
				}
			});
		}
	}
</script>


<style scoped>
	.content >>> img{
		width: 100%;
	}
	.content >>> span{
		font-size: 0.32rem !important;
		color: #555a66;
	}
	.content >>> strong{
		color: #555a66;
		font-weight: 500;
	}
</style>
<style lang="scss" scoped>
	.detail{
		padding-top: 0.88rem;
		
		.main{
			padding: 0.24rem;
			
			.title{
				font-size: 0.4rem;
				line-height: 0.66rem;
			}
			.title-sub{
				color: #A1A7B2;
				margin-top: 0.24rem;
				margin-bottom: 0.8rem;
				
				span{
					margin-right: 0.24rem;
				}
			}
		}
	}
</style>
